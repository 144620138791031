import axios from 'axios';
import store from '@/store';

let apiClient;

export const createApiClient = (baseURL) => {
  apiClient = axios.create({
    baseURL,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json;charset=UTF-8',
    },
  });
  apiClient.interceptors.request.use((config) => {
    const token = store.getters['oidcStore/oidcAccessToken'];
    if (token) {
      // eslint-disable-next-line no-param-reassign
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  (err) => {
    console.warn('err: ', err);
    return Promise.reject(err.response);
  });
  return apiClient;
};
